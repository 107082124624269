import { Skeleton } from "antd";
import React from "react";
import CompanyProfileHeaderStyle from "./CompanyProfileHeaderStyle";
import { GlobalOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { useTranslation } from "next-i18next";
import { FilesAPI } from "@common/constants";
import ShareButton from "@components/ShareButton/ShareButton";
import { Waves } from "@common/icons";
import useWidth from "@helpers/hooks/useWidth";
import { Group16356 } from "@common/icons";
import companyPlac from "../../public/assets/common/images/Company.svg";
import Image from "next/image";
import VisitKsaToolbarSlug from "@components/visitKsa/VisitKsaToolbarSlug/VisitKsaToolbarSlug";

interface CompanyProfileHeaderProps {
	isShrink: boolean;
	data: any;
	companyName: any;
}

const CompanyProfileHeader: React.FC<CompanyProfileHeaderProps> = ({ data, isShrink, companyName }) => {
	const { t } = useTranslation("website");
	const { width } = useWidth();

	return (
		<CompanyProfileHeaderStyle isShrinked={isShrink}>
			<div className="content">
				<div className="container">
					<div className="profile-header-waves">
						<Waves />
					</div>
					<div className="profile-header-content">
						<div className="company-profile-data">
							{width > 992 && (
								<>
									<div className="company--img">
										{data ? (
											<Image width={isShrink ? 50 : 150} height={isShrink ? 50 : 150} src={data?.image ? `${FilesAPI}/AccessFile/${data?.image}` : companyPlac} alt="" />
										) : (
											<Skeleton.Image style={{ width: isShrink ? 50 : 150, height: isShrink ? 50 : 150 }} active />
										)}
									</div>
									{isShrink && <h4 className="company-name">{data?.name}</h4>}
								</>
							)}
							<div className="company-info-holder">
								{data ? (
									<>
										{width <= 992 && (
											<>
												<div className="company--img">
													{data ? (
														<Image width={50} height={50} src={data?.image ? `${FilesAPI}/AccessFile/${data?.image}` : companyPlac} alt="img" />
													) : (
														<Skeleton.Image style={{ width: 50, height: 50 }} active />
													)}
												</div>
											</>
										)}
										<div className="company-info-top">
											<h4 className="company-name">{data?.name}</h4>
											<p className="company-location">
												<GlobalOutlined />
												<span className="location--name">{data?.companyNationality}</span>
											</p>
										</div>
										{width <= 992 && <ShareButton companyName={companyName} />}
									</>
								) : (
									<>
										<Skeleton.Input active />
										<Skeleton.Input active />
									</>
								)}
								{data ? (
									<div className="company-contact">
										<a href={"tel:" + data?.countryCode + data?.contactNumber}>
											{" "}
											<PhoneOutlined /> {data?.countryCode + data?.contactNumber}
										</a>
										<a href={"mailto:" + data?.companyEmail}>
											{" "}
											<MailOutlined /> {data?.companyEmail}
										</a>
									</div>
								) : (
									<>
										<Skeleton.Input active block />
									</>
								)}
							</div>
							{width > 992 && <ShareButton companyName={companyName} />}
						</div>
					</div>
				</div>
			</div>
			<VisitKsaToolbarSlug />
		</CompanyProfileHeaderStyle>
	);
};

export default CompanyProfileHeader;
