import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Head from "next/head";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { FilesAPI } from "@common/constants";
import Header from "@components/website/Header/Header";
import Api from "../config/network/ApiConfig";
import CompanyProfileHeader from "@components/CompanyProfileHeader/CompanyProfileHeader";
import { useScroll } from "@helpers/hooks/useScroll";
import { useSession } from "next-auth/react";
import SlugActivity from "@components/SlugActivity/SlugActivity";
import VisitKsaExplore from "@components/visitKsa/VisitKsaExploreSlug/VisitKsaExplore";
import SlugPackage from "@components/SlugPackge/SlugPackge";

function BusinessProfile() {
	const router = useRouter();
	const { t } = useTranslation("website");
	const [companyData, setCompanyData] = useState<any>(null);
	const { scrolling, scrollY } = useScroll();
	const { data: user } = useSession();
	const currentTheme =
		localStorage?.getItem("TenantConfiguration") && localStorage?.getItem("TenantConfiguration") !== "undefined" ? JSON?.parse(localStorage?.getItem("TenantConfiguration") || "{}") : null;

	if (user && [0, 1]?.includes(+user?.userType)) {
		router.push("/admin/security/admins");
		return;
	}
	useEffect(() => {
		handelGetComanyData();
	}, []);

	const handelGetComanyData = () => {
		Api.get("GetawayAPI", `/Company/GetCompany?uniqName=${router.query.slug!}`)
			.then((res: any) => {
				if (res?.responseCode === 500) {
					router.push("/");
				}
				setCompanyData(res?.responseData || null);
			})
			.then((res: any) => {});
	};

	useEffect(() => {}, [router?.asPath]);

	return (
		<div className="SearchContainer">
			<Head>
				<title>{companyData?.name}</title>
				<meta property="title" content={companyData?.name} />
				<meta property="og:site_name" content={companyData?.name} />
				<meta property="og:title" content={companyData?.name} />
				<meta property="og:image" content={`${FilesAPI}/AccessFile/${companyData?.image}`} />
				<link rel="apple-touch-icon" href={`${FilesAPI}/AccessFile/${companyData?.image}`} />
				{/* <!-- Google / Search Engine Tags --> */}
				<meta itemProp="name" content={companyData?.name} />
				<meta itemProp="description" content="We aim to refine tourism content … to enrich guest experience throughout the trip." />
				<meta itemProp="image" content={`${FilesAPI}/AccessFile/${companyData?.image}`} />

				{/* <!-- Facebook Meta Tags --> */}
				<meta property="og:url" content={window.location.href} />
				<meta property="og:type" content="website" />
				<meta property="og:title" content={companyData?.name} />
				<meta property="og:description" content="We aim to refine tourism content … to enrich guest experience throughout the trip." />
				<meta property="og:image" content={`${FilesAPI}/AccessFile/${companyData?.image}`} />

				{/* <!-- Twitter Meta Tags --> */}
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:title" content={companyData?.name} />
				<meta name="twitter:description" content="We aim to refine tourism content … to enrich guest experience throughout the trip." />
				<meta name="twitter:image" content={`${FilesAPI}/AccessFile/${companyData?.image}`} />
				<link rel="icon" href={`${FilesAPI}/AccessFile/${currentTheme?.favIcon}`} />
			</Head>
			<Header user={user} />
			<div className="packages-wrapper" style={{ paddingTop: 0 }}>
				<CompanyProfileHeader data={companyData} isShrink={scrollY > 50} companyName={router.query.slug!} />
				{!!!router?.query?.type ? (
					<div className="">
						<div className="market-home-wrapper">
							<VisitKsaExplore companyId={companyData?.id} />
							<VisitKsaExplore companyId={companyData?.id} isThingsToDo />
						</div>
					</div>
				) : router?.query?.type === "activity" ? (
					<SlugActivity companyData={companyData} />
				) : (
					<SlugPackage companyData={companyData} />
				)}
			</div>
		</div>
	);
}

export async function getServerSideProps(context: any) {
	return {
		props: {
			...(await serverSideTranslations(context?.locale, ["website"]))
		}
	};
}

export default BusinessProfile;
