import styled from "@emotion/styled";
import { Button, Form, Input, Modal, Tooltip, Typography } from "antd";
import React from "react";
import { FacebookShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { FacebookIcon, TelegramIcon, TwitterIcon, WhatsappIcon } from "react-share";
import { useTranslation } from "next-i18next";
const ShareNodalContent = styled.div`
	display: flex;
	flex-direction: column;
	padding: 30px;
	@media (max-width: 580px) {
		padding: 20px 10px;
	}
	.share-list-holder {
		display: flex;
		gap: 16px;
		> button {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 10px;
		}
	}
	.share-link-holder {
		margin-top: 12px;
		.share-link--label {
			font-size: 18px;
			margin: 20px 0;
		}
	}
`;

export default function ShareButtonModal({ visible, handelCloseModal, companyName }: any) {
	const { Paragraph } = Typography;
	const shareUrl = window.location.origin + "/" + companyName;
	const { t, i18n } = useTranslation("website");

	return (
		<Modal title={t("profile.share.share")} wrapClassName="share-modal-holder" centered footer={null} closable open={visible} onCancel={() => handelCloseModal(false)}>
			<ShareNodalContent>
				<div className="share-list-holder">
					<WhatsappShareButton url={shareUrl}>
						<WhatsappIcon size={64} round={true} /> {t("profile.share.Whatsapp")}
					</WhatsappShareButton>
					<FacebookShareButton url={shareUrl}>
						{" "}
						<FacebookIcon size={64} round={true} /> {t("profile.share.Facebook")}
					</FacebookShareButton>
					<TelegramShareButton url={shareUrl}>
						{" "}
						<TelegramIcon size={64} round={true} /> {t("profile.share.Telegram")}
					</TelegramShareButton>
					<TwitterShareButton url={shareUrl}>
						{" "}
						<TwitterIcon size={64} round={true} /> {t("profile.share.Twitter")}
					</TwitterShareButton>
				</div>
				<div className="share-link-holder">
					<h6 className="share-link--label">{t("profile.share.Page Link")}</h6>
					<Input.Group size="large" compact>
						<Input style={{ width: "calc(100% - 50px)" }} defaultValue={shareUrl} />
						<Tooltip title={t("profile.share.Page Link")}>
							<Button size="large">
								<Paragraph copyable={{ tooltips: false, icon: null, text: shareUrl }}></Paragraph>
							</Button>
						</Tooltip>
					</Input.Group>
				</div>
			</ShareNodalContent>
		</Modal>
	);
}
