import styled from "@emotion/styled";

export const VisitKsaStyle = styled.div`
	/* display: grid;
	grid-template-columns: 1fr repeat(12, 72px) 1fr;
	grid-template-rows: 127px 490px 1fr;
	column-gap: 24px;
	justify-content: center; */
	background-color: #ffffff;
	/* min-height: 100vh; */

	/* .container {
		padding-left: 24px !important;
		padding-right: 24px !important;
    
	} */
`;

export const VisitKsaHeading = styled.h2`
	font-weight: 600;
	font-size: 36px;
	line-height: 54px;
	/* identical to box height */

	/* Dark text */
	color: #343434;

	@media only screen and (max-width: 768px) {
		font-size: 32px;
	}
`;
