import { useTranslation } from "next-i18next";
import React from "react";
import { VisitKsaToolbarThingsToDoIcon, VisitKsaToolbarUmrahIcon, VisitKsaTourTripIcon, VisitKsaUmrahJourneyIcon } from "../VisitKsaIcon";
import { VisitKsaToolbarStyle } from "./VisitKsaToolbarStyle";
import { PlusCircleOutlined } from "@ant-design/icons";
import Link from "next/link";
import { useRouter } from "next/dist/client/router";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { Button } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";

interface VisitKsaToolbarProps {}

const VisitKsaToolbarSlug: React.FC<VisitKsaToolbarProps> = () => {
	const { t, i18n } = useTranslation("website");
	const router = useRouter();
	const userLocation = useSelector((state: any) => state?.user?.userLocation);
	const isArabicLang = i18n.language === "ar";

	const onTourTripClick = () => {
		router.query.type = "package";
		router.push(router);
	};
	const onThingsToDoClick = () => {
		router.query.type = "activity";
		router.push(router);
	};

	return (
		<VisitKsaToolbarStyle>
			<div className="container">
				<div className="visit-bar-content">
					{router.query.type && (
						<Button
							className="primary-light"
							type="primary"
							style={{ width: "fit-content" }}
							onClick={() => {
								router.push(`/${router?.query?.slug}`);
							}}
						>
							{isArabicLang ? <RightOutlined /> : <LeftOutlined />}
							{t("common.buttons.Back")}
						</Button>
					)}
					<div className={`visit-ksa-sub-header-btn ${router?.query?.type === "package" ? "active" : ""}`} onClick={onTourTripClick}>
						<VisitKsaTourTripIcon />
						<p>{t("profile.package.Packages")}</p>
					</div>
					<div className={`visit-ksa-sub-header-btn ${router?.query?.type === "activity" ? "active" : ""}`} onClick={onThingsToDoClick}>
						<VisitKsaToolbarThingsToDoIcon />
						<p>{t("common.buttons.thingsToDo")}</p>
					</div>
				</div>
			</div>
		</VisitKsaToolbarStyle>
	);
};

export default VisitKsaToolbarSlug;
