import { useQuery } from "@tanstack/react-query";
import useApi from "../../../config/network/useApi";
import { queryKeys } from "@config/react-query/queryKeys";

export const useGetB2cSinglePackage = ({ id, isEnable = false }: { id: string | undefined | any; isEnable: boolean }) => {
	const api = useApi("GetawayAPI");
	const http = async () => {
		const res = await api.get(`/Packages/GetPackageDetails/${id}`);
		return res;
	};
	const { data, isFetching, isLoading } = useQuery([queryKeys.package.getSinglePackageB2c, id], http, {
		select: (res) => {
			return res?.responseData;
		},
		enabled: isEnable
		// refetchOnWindowFocus: true
	});
	return { data, isFetching, isLoading };
};

export const useGetBookingConfirmationPackage = ({ id, isEnable }: { id: string | undefined | any; isEnable: boolean }) => {
	const api = useApi("GetawayAPI");
	const http = async () => {
		const res = await api.get(`/Packages/VerifyBookingPayment/?BookingID=${id}`);
		return res;
	};
	const { data, isFetching, isLoading } = useQuery([queryKeys.package.getBookingConfirmationPackage, id], http, {
		select: (res) => {
			return res?.responseData;
		},
		enabled: isEnable
	});
	return { data, isFetching, isLoading };
};

export const useGetBookingConfirmationUmurah = ({ id }: { id: string | undefined | any }) => {
	const api = useApi("GetawayAPI");
	const http = async () => {
		const res = await api.get(`/Umrah/VerifyBookingPayment?BookingID=${id}`);
		return res;
	};
	const { data, isFetching, isLoading, error, failureReason } = useQuery([queryKeys.package.getBookingConfirmationUmurah, id], http, {
		select: (res) => {
			return res?.responseData;
		}
	});
	return { data, isFetching, isLoading, error, failureReason };
};

export const useGetBookingPackage = ({ id }: { id: string | undefined | any }) => {
	const api = useApi("GetawayAPI");
	const http = async () => {
		const res = await api.get(`/Packages/GetBookingPackageDetails/${id}`);
		return res;
	};
	const { data, isFetching, isLoading } = useQuery([queryKeys.package.getBookingPackage, id], http, {
		select: (res) => {
			return res?.responseData;
		}
	});
	return { data, isFetching, isLoading };
};

export const useGetB2cPackages = ({ body }: { body: any }) => {
	const api = useApi("GetawayAPI");
	const http = async () => {
		const res = await api.post(`/Packages/GetList`, body);
		return res;
	};

	const { data, isFetching, isLoading } = useQuery([queryKeys.package.getPackageB2cList, body], http, {
		select: (res) => {
			return res?.responseData;
		},
		enabled: !!body
		// refetchOnWindowFocus: true
	});

	return { data, isFetching, isLoading };
};

export const useGetSlugPackages = ({ body, slug }: { body: any; slug: any }) => {
	const api = useApi("GetawayAPI");
	const http = async () => {
		const res = await api.post(`/Packages/GetList`, body);
		return res;
	};

	const { data, isFetching, isLoading } = useQuery([queryKeys.package.useGetSlugPackages, body, slug], http, {
		select: (res) => {
			return res?.responseData;
		},
		enabled: !!slug
		// refetchOnWindowFocus: true
	});

	return { data, isFetching, isLoading };
};

export const useGetSlugActivity = ({ body, slug }: { body: any; slug: any }) => {
	const api = useApi("GetawayAPI");
	const http = async () => {
		const res = await api.post(`/ThingsToDo/GetList`, body);
		return res;
	};

	const { data, isFetching, isLoading } = useQuery([queryKeys.package.useGetSlugActivity, body, slug], http, {
		select: (res) => {
			return res?.responseData;
		},
		enabled: !!slug
		// refetchOnWindowFocus: true
	});

	return { data, isFetching, isLoading };
};

export const useGetCompanyPackages = ({ body }: { body: any }) => {
	const api = useApi("GetawayAPI");
	const http = async () => {
		const res = await api.post(`/Packages/GetCompanyList`, body);
		return res;
	};

	const { data, isFetching, isLoading } = useQuery([queryKeys.package.getCompanyPackages, body], http, {
		select: (res) => {
			return res?.responseData;
		},
		enabled: !!body
	});

	return { data, isFetching, isLoading };
};

export const uesGetPackageBookingSummary = ({ id }: { id: any }) => {
	const api = useApi("GetawayAPI");
	const http = async () => {
		const res = await api.get(`/Packages/GetPackageBookingSummary?PageSize=${10}&PageIndex=${0}&PackageID=${id}&SearchKeyWord=${""}`);
		return res;
	};

	const { data, isFetching, isLoading } = useQuery([queryKeys.package.getPackageBookingSummary, id], http, {
		select: (res) => {
			return res?.responseData;
		}
		// refetchOnWindowFocus: true
	});

	return { data, isFetching, isLoading };
};

export const useGetPackageItemTypeById = ({ id, enabled }: { id: any, enabled: boolean }) => {
  const api = useApi("PackagesAPI");
  const http = async () => {
    const res = await api.get(`PackageItemType/PackageItemTypeById?packageItemTypeId=${id}`);
    return res;
  };

  const { data, isFetching, isLoading } = useQuery([queryKeys.package.getPackageItemTypeById, id], http, {
    select: (res) => {
      return res?.responseData;
    },
    enabled: enabled,
  });

  return { data, isFetching, isLoading };
}