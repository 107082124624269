import styled from "@emotion/styled";

export const VisitKsaExploreCarouselStyle = styled.div`
	@media only screen and (max-width: 768px) {
		width: 94%;
		margin: 0 auto;
	}
`;

export const VisitKsaExploreCarouselItemStyle = styled.div`
	.visit-ksa-explore-item {
		width: 264px;
		min-height: 490px;
		display: flex;
		flex-direction: column;
		border: 1px solid #d6d9d3;
		border-radius: 12px;
		overflow: hidden;
		margin: 0;
		color: #fff;
		text-align: center;
		margin-bottom: 20px;
		transition: all ease-in-out 0.3s;
		margin-right: auto;
		margin-left: auto;
		border: 1px solid #d6d9d3;
		&:hover {
			transition: all ease-in-out 0.3s;
			box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.12);
			border: none;
		}

		@media only screen and (max-width: 768px) {
			/* width: 320px; */
			width: 90%;
		}
		.package--types {
			font-size: 12px;
			font-weight: 500;
			color: #0a7db9;
			text-align: left;
			&:lang(ar) {
				text-align: right;
			}
		}
		.visit-ksa-explore-item-img {
			height: 328px;
			min-height: 328px;
			border-radius: 12px;
			position: relative;
			img,
			.ant-skeleton,
			.ant-skeleton-image {
				height: 100%;
				min-height: initial;
				width: 100%;
				object-fit: cover;
				border-radius: 12px;
			}
		}

		.visit-ksa-explore-item-info-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 10px;
			padding: 16px;
			padding-top: 10px;
		}

		.visit-ksa-explore-item-name {
			font-weight: 500;
			font-size: 16px;
			text-align: left;
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			/* line-height: 1.6; */
			color: ${({ theme }) => theme.token.colorTextBase};
			&:lang(ar) {
				text-align: right;
			}
		}

		.visit-ksa-explore-item-date,
		.visit-ksa-explore-item-location {
			display: flex;
			align-items: center;
			gap: 10px;
			svg {
				font-size: 22px;
				color: #949e89;
			}
		}
		.visit-ksa-explore-item-location {
			font-weight: 500;
			font-size: 12px;
			color: #595959;
			/* flex-wrap: wrap; */
			white-space: nowrap;
		}
		.visit-ksa-explore-item-date {
			font-weight: 500;
			font-size: 14px;
			color: #595959;
			white-space: nowrap;
			.ant-skeleton {
				width: 90px;
				overflow: hidden;
				.ant-skeleton-input {
					width: auto;
				}
			}
		}

		.visit-ksa-explore-item-price {
			margin-top: auto;
			color: #595959;
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
			font-size: 12px;
			p {
				display: flex;
				align-items: flex-end;
				gap: 5px;
			}
			width: 100%;
			span {
				line-height: 1;
				font-weight: 700;
				font-size: 22px;
				color: ${({ theme }) => theme.token.secColor};
			}
		}
	}

	.old-price {
		span {
			color: #e52729 !important;
			font-size: 18px !important;
			text-decoration-line: line-through;
		}
	}
`;
