import styled from "@emotion/styled";

export const VisitKsaExploreStyle = styled.div`
	padding-bottom: 100px;
	@media only screen and (max-width: 768px) {
		padding-bottom: 40px;
	}
	.visit-ksa-explore-title {
		margin-bottom: 10px;

		a {
			display: none;
		}
		@media only screen and (max-width: 768px) {
			display: flex;
			justify-content: space-between;
			align-items: center;
			a {
				display: block;
				span {
					color: #343434;
					font-size: 20px;
				}
			}
		}
	}
	.visit-ksa-explore-see-more-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		margin-bottom: 32px;

		.visit-ksa-explore-see-more-btn {
			@media only screen and (max-width: 768px) {
				display: none;
			}
			a {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 5px;
				color: #343434;
				text-decoration: none;
				p {
					font-weight: 500;
					font-size: 14px;
				}
			}
		}
	}

	/* .visit-ksa-explore-carousel-arrow { */
	.slick-arrow,
	.slick-next:focus,
	.slick-prev:focus {
		background-color: #ffffff;
		width: 40px;
		height: 40px;
		border-radius: 20px;
		display: flex !important;
		z-index: 10;
		justify-content: center;
		align-items: center;

		&:hover {
			background-color: #ffffff;
		}
		top: 150px;
		.anticon {
			font-size: 20px;
			color: ${({ theme }) => theme.token.colorPrimaryActive};
		}
	}

	.slick-next {
		right: -10px !important;
		@media only screen and (max-width: 768px) {
			right: -20px !important;
		}
	}

	.slick-prev {
		left: -10px !important;
		@media only screen and (max-width: 768px) {
			left: -20px !important;
		}
	}
`;
