import { Carousel, Skeleton } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import React from "react";
import ItemBg from "../../../../public/assets/common/images/popular1.png";
import Image from "next/image";
import { VisitKsaExploreCarouselItemStyle, VisitKsaExploreCarouselStyle } from "./VisitKsaExploreCarouselStyle";
import { VisitKsaHeroDateIcon } from "@components/visitKsa/VisitKsaIcon";
import dayjs from "dayjs";
import Link from "next/link";
import { MdOutlineCalendarToday, MdOutlinePlace, MdTimelapse } from "react-icons/md";

interface VisitKsaExploreCarouselProps {
	data: any;
	isLoading: boolean;
	isThingsToDo?: boolean;
}

const VisitKsaExploreCarousel: React.FC<VisitKsaExploreCarouselProps> = ({ data, isLoading, isThingsToDo }) => {
	return (
		<VisitKsaExploreCarouselStyle>
			<Carousel
				// slidesPerRow={4}

				arrows
				dots={false}
				nextArrow={
					<button className="visit-ksa-explore-carousel-arrow">
						<RightOutlined />
					</button>
				}
				prevArrow={
					<button className="visit-ksa-explore-carousel-arrow">
						<LeftOutlined />
					</button>
				}
				lazyLoad={"progressive"}
				slidesToShow={4}
				slidesToScroll={1}
				autoplay={true}
				infinite={data?.length > 4}
				speed={600}
				responsive={[
					{
						breakpoint: 1600,
						settings: {
							slidesToShow: 4
						}
					},
					{
						breakpoint: 1200,
						settings: {
							centerMode: true,
							slidesToShow: 3.1
						}
					},
					{
						breakpoint: 992,
						settings: {
							centerMode: true,
							slidesToShow: 2.5
						}
					},
					{
						breakpoint: 768,
						settings: {
							centerMode: false,
							slidesToShow: 2
						}
					},
					{
						breakpoint: 600,
						settings: {
							centerMode: true,
							slidesToShow: 1.15
						}
					},

					{
						breakpoint: 450,
						settings: {
							// centerMode: true,
							slidesToShow: 1
						}
					},
					{
						breakpoint: 360,
						settings: {
							centerMode: false,
							slidesToShow: 1
						}
					}
				]}
			>
				{isLoading
					? [1, 2, 3, 4]?.map((card: any, idx: number) => {
							return (
								<div key={idx}>
									<VisitKsaExploreCarouselItem loading={true} card={card} />
								</div>
							);
					  })
					: data?.map((card: any, idx: number) => {
							return (
								<div key={idx}>
									<VisitKsaExploreCarouselItem loading={false} card={card} isThingsToDo={isThingsToDo} />
								</div>
							);
					  })}
			</Carousel>
		</VisitKsaExploreCarouselStyle>
	);
};

export default VisitKsaExploreCarousel;

const VisitKsaExploreCarouselItem = ({ card, loading, isThingsToDo }: any) => {
	return (
		<VisitKsaExploreCarouselItemStyle>
			<Link href={isThingsToDo ? `/things-to-do/${card?.internalID}` : `/p/${card?.internalID}`} className="visit-ksa-explore-item">
				<div className="visit-ksa-explore-item-img">
					{loading ? <Skeleton.Image active /> : <Image fill alt={card?.name || "images"} src={`${process.env.FILES_API}/AccessFile/${card?.photoCoverID}` || ""} />}
				</div>

				<div className="visit-ksa-explore-item-info-wrapper">
					{loading ? <Skeleton.Input active block /> : <h4 className="visit-ksa-explore-item-name">{card?.packageName || "-"}</h4>}
					<div className="visit-ksa-explore-item-location">
						<MdOutlinePlace />
						{loading ? (
							<Skeleton.Input active block />
						) : (
							<>
								<p>
									{card?.cityName}, {card?.countryName}
								</p>
							</>
						)}
					</div>
					<div className="package--types">
						<p>{card?.packageTypes && card?.packageTypes?.join(" , ")}</p>
					</div>
					<div className="visit-ksa-explore-item-date">
						<MdOutlineCalendarToday style={{ fontSize: 20 }} />
						{/* <MdTimelapse style={{ fontSize: 20 }} /> */}
						{loading ? <Skeleton.Input active /> : <p>{dayjs(card?.startDate).format("D MMM YYYY")}</p>}
						<p>-</p>
						{loading ? <Skeleton.Input active /> : <p>{dayjs(card?.endDate).format("D MMM YYYY")}</p>}
					</div>
					<div className="visit-ksa-explore-item-price ">
						{/* <p className="old-price">
							{t("common.sentence.SAR")}
							<span>256</span>
						</p> */}
						{loading ? (
							<Skeleton.Input active />
						) : (
							<p>
								{card?.currencyAbbreviation}
								<span>{card?.price}</span>
							</p>
						)}
					</div>
				</div>
			</Link>
		</VisitKsaExploreCarouselItemStyle>
	);
};
