import { css } from "@emotion/react";
import { Button } from "antd";
import React, { useState } from "react";
import { ShareAltOutlined } from "@ant-design/icons";

import ShareButtonModal from "./ShareButtonModal";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
export default function ShareButton({ companyName, isBtnOverlay = false, isReview = false }: any) {
	const { t, i18n } = useTranslation("website");

	// const ShareButtonStyles = css`
	//     /* background: #fcfcfc; */
	//     background-color: transparent !important;
	//     border-color: #ffffff;
	//     color: #ffffff;
	//     min-width: 95px;
	// `;

	const ShareButtonStyles = styled.div`
		.primary-light {
			background-color: transparent !important;
			border-color: #ffffff;
			color: #ffffff !important;
			min-width: 95px;
			.anticon {
				font-size: 16px;
			}
			@media only screen and (max-width: 992px) {
				min-width: 45px;
				span {
					display: none;
				}
				.anticon {
					display: block;
					font-size: 22px;
				}
			}
			&.btn-overlay {
				background-color: ${(props: any) => props.theme.token.secBtnBg} !important;
				border: 1px solid ${(props: any) => props.theme.token.secBtnBorderColor};
				color: #3f464e !important;
			}
		}
	`;
	const [OpenShareModal, setOpenShareModal] = useState(false);
	const handelOpenShareModal = (state = false) => {
		setOpenShareModal(state);
	};
	return (
		<ShareButtonStyles>
			<Button
				icon={<ShareAltOutlined />}
				size="small"
				type="primary"
				ghost
				className={` primary-light  ${isBtnOverlay ? " btn-overlay" : ""}`}
				onClick={() => {
					if (isReview) return;
					handelOpenShareModal(true);
				}}
			>
				{t("profile.createPackage.share")}
			</Button>
			<ShareButtonModal handelCloseModal={() => handelOpenShareModal(false)} visible={OpenShareModal} companyName={companyName} />
		</ShareButtonStyles>
	);
}
