export const handleHttpErrors = (data?: any) => {
    switch (data?.responseCode || data?.status) {
        case 401:
            // refreshTokenFun();
            // const result = signOut({
            //     redirect: false,
            //     callbackUrl: "/auth/login"
            // });
            // Router.replace("/auth/login", "/auth/login", { locale: Router?.router?.state?.locale ? "ar" : "en" });
            // signOut();
            // Router.push("/");
            throw { errors: ["Not authorized"], code: data?.responseCode };
        case 404:
            throw {
                errors: ["Page/Service not found"],
                code: data?.responseCode
            };
        case 500:
            throw {
                errors: ["Internal server error"],
                code: data?.responseCode
            };
        default:
            throw {
                errors: [data?.responseMessage],
                code: data?.responseCode
            };
    }
};

export default async function actionCall(options?: any, dispatch?: any, callback?: any, errorHandling?: any, toastError: boolean = true) {
    options?.reqResult && dispatch(options?.reqResult?.request[options?.reqResult?.name.replace("Success", "Request")]());
    try {
        // call the service
        const { data } = (await options.service()) || "";

        // if there is callback function call it!
        callback && callback(data);

        // dispatch action by redux toolkit
        options?.reqResult && dispatch(options?.reqResult?.request[options?.reqResult?.name](data?.responseData?.data || data?.responseData || data));

        // dispatch action to redux
        options?.success && dispatch({ type: options?.success, payload: data?.responseData?.data || data?.responseData || data });

        return data;
    } catch (err: any) {
        // dispatch action to redux
        // use error that added "Explicitly" or replace SUCCESS with ERROR : ACTION_TYPE_SUCCESS ==> ACTION_TYPE_ERROR
        // for custom error handling
        errorHandling && errorHandling(err);
        let type = options.error ? options.error : options?.success?.replace("SUCCESS", "ERROR");
        options?.success && dispatch({ type, payload: [err?.response?.data?.responseMessage] });

        // dispatch action by redux toolkit
        options?.reqResult && dispatch(options?.reqResult?.request[options?.reqResult?.name.replace("Success", "Error")]([err?.response?.data?.responseMessage]));
        handleHttpErrors(err?.response?.data || err.response);
    }
}
