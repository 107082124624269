import styled from "@emotion/styled";

export const VisitKsaToolbarStyle = styled.div`
	position: relative;
	border-bottom: 1px solid #f2f2f0;
	top: -1px;
	background-color: #fff;
	@media only screen and (max-width: 768px) {
		display: none;
	}

	.visit-bar-content {
		padding-top: 15px;
		padding-bottom: 15px;
		margin: 0 auto;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 50px;
		height: 70px;
	}

	.visit-ksa-sub-header-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		padding: 12px;
		border-radius: 6px;
		gap: 10px;
		font-size: 16px;
		color: #595959;
		cursor: pointer;
		transition: all ease-in-out 0.2s;
		&.active {
			background-color: ${({ theme }: any) => theme.token.colorPrimary};
			color: #fff;
			svg {
				stroke: #fff;
				/* stroke-width: 50px; */
			}
			/* & path {
				stroke: #fff;
			} */
			/* color: ${({ theme }: any) => theme.token.colorBtnColor}; */
		}
		&:hover {
			color: #000;
			/* font-weight: 500; */
			transition: all ease-in-out 0.2s;
		}
	}

	.visit-ksa-sub-header-umrah-jounrey-btn {
		display: flex;
		flex-direction: row;
		margin-inline-start: auto;
		cursor: pointer;
		align-items: center;
		padding: 12px;
		justify-content: space-between;
		padding-inline-end: 4px;
		gap: 8px;

		width: 228px;
		height: 40px;

		/* button border */

		background: #8cb262;
		/* button border */

		border: 1px solid #8cb262;
		border-radius: 33px;

		/* Inside auto layout */

		flex: none;
		order: 4;
		flex-grow: 0;

		.visit-ksa-sub-header-umrah-journey-visa-btn {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 4px;
			padding-inline-end: 12px;
			gap: 8px;

			width: 79px;
			height: 32px;

			/* White bg */

			background: #ffffff;
			border-radius: 16px;

			/* Inside auto layout */

			flex: none;
			order: 1;
			flex-grow: 0;

			.anticon-plus-circle {
				color: #8cb262;
				font-size: 20px;
				/* border: 1px solid #8cb262; */
			}
		}
	}
`;
