import styled from "@emotion/styled";

const shrinkedHeight = "88px";
const fullHeight = "150px";
const CompanyProfileHeaderStyle = styled.div<{ isShrinked: boolean }>`
	height: 280px;
	/* ${(props) => (props.isShrinked ? `height: ${shrinkedHeight};` : `height: 220px;`)} */
	position: sticky;
	top: 71px; // 71px is the height of the header
	z-index: 25;
	@media only screen and (max-width: 992px) {
		top: 57px; // 57px is the height of the header
		height: 170px;
	}
	.content {
		width: 100%;
		${(props) => (props.isShrinked ? `height: ${shrinkedHeight};` : `height: 220px;`)}
		/* height: 220px; */
    display: flex;
		align-items: center;
		background: ${({ theme }: any) => theme.token.linearBg};
		transition: height 0.3s ease;
		@media only screen and (max-width: 992px) {
			height: 170px;
			padding-top: 16px;
			padding-bottom: 16px;
			top: 57px; // 57px is the height of the header
			${(props) => (props.isShrinked ? `height: 88px` : `height: 170px;`)}
		}
	}
	.container {
		position: relative;
	}
	.profile-header-waves {
		${(props) => (props.isShrinked ? `display: none;` : `display: block;`)}
		position: absolute;
		bottom: -42px;
		right: 0;
		&:lang(ar) {
			right: auto;
			left: 0;
		}
		@media only screen and (max-width: 992px) {
			display: none;
		}
	}

	.profile-header-content {
		position: relative;
		.company-profile-data {
			display: flex;
			align-items: ${(props) => (props.isShrinked ? `center` : `flex-start`)};
			gap: ${(props) => (props.isShrinked ? `12px` : `32px`)};
			.company-name {
				color: #fff;
			}
			img {
				border-radius: 12px;
				/* mix-blend-mode: multiply; */
				@media only screen and (max-width: 992px) {
					object-fit: cover;
				}
			}
			@media only screen and (max-width: 992px) {
				flex-wrap: wrap;
				.company--img {
					.ant-image {
						width: ${(props) => (props.isShrinked ? "50px" : "80px")} !important;
						height: ${(props) => (props.isShrinked ? "50px" : "80px")} !important;
						img {
							width: 100% !important;
							height: 100% !important;
						}
					}
				}
			}
		}
		.company-info-holder {
			visibility: ${(props) => (props.isShrinked ? `hidden` : `visible`)};
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: ${(props) => (props.isShrinked ? shrinkedHeight : fullHeight)};
			transition: height 0.3s ease;
			flex: 1;
			@media only screen and (max-width: 992px) {
				height: auto;
				visibility: visible;
				gap: 19px;
				flex-direction: row;
				flex-wrap: wrap;
				align-items: center;
				justify-content: ${(props) => (props.isShrinked ? `space-between` : `flex-start`)};
			}
			.company-info-top {
				flex: 1;
			}
			.company-name {
				font-size: 25px;
				color: #fff;
				margin-bottom: 10px;
				@media only screen and (max-width: 992px) {
					font-size: 16px;
					max-width: 48vw;
				}
			}
			.company-location {
				display: flex;
				align-items: center;
				gap: 12px;
				svg {
					color: #fff;
				}
				.location--name {
					color: #fff;
				}
			}
		}
		.company-contact {
			height: 48px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: rgba(0, 0, 0, 0.3);
			border-radius: 8px;
			max-width: 550px;
			padding: 5px 18px;
			padding-inline-end: 50px;
			@media only screen and (max-width: 992px) {
				height: auto;
				display: ${(props) => (props.isShrinked ? `none` : `block`)};
				flex-direction: column;
				align-items: flex-start;
				gap: 12px;
				padding-top: 8px;
				padding-bottom: 8px;
				flex: 1;
			}
			a {
				display: flex;
				align-items: center;
				gap: 14px;
				color: #fff;
			}
		}
	}
`;
export default CompanyProfileHeaderStyle;
