import { BedIcon, GreyCloseBtnIcon, PurplePlusBtnIcon } from "@common/icons";
import { Button, Col, Form, FormInstance, InputNumber, Row, Select, Space, message } from "antd";
import { useTranslation } from "next-i18next";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { PlusCircleFilled, MinusCircleFilled, PlusCircleOutlined } from "@ant-design/icons";
import classes from "./PaxInfoDrawer.module.scss";
import Drawer from "@components/Drawer/Drawer";
import useCalcAllRooms from "@helpers/hooks/useCalAllRooms";
import { useIsEqualRoomGroups } from '@helpers/hooks/useIsEqualRoomGroups';

const FIELD_NAME = "roomAndTravelers";
interface PaxInfoDrawerProps {
	visible: boolean;
	onFormlistModalSave: () => void;
	form: FormInstance;
	name?: string;
	onCancel: () => void;
	isB2c?: boolean;
	limit?: number;
}

const childAgesOptions = [...Array(10).keys()].map((el) => ({ value: el + 1, label: el + 1 }));
const PaxInfoDrawer: React.FC<PaxInfoDrawerProps> = ({ visible, onFormlistModalSave, form, name, onCancel, limit, isB2c }) => {
	const fieldsRef = useRef<HTMLDivElement | null>(null);
	const { t } = useTranslation("website");
	const roomsValue = Form.useWatch(name ?? FIELD_NAME);
	const [currentValue, setCurrentValue] = useState({});
	const roomWatcher = Form.useWatch([name ?? FIELD_NAME], form);
	const allRoomCalc = useCalcAllRooms(roomWatcher);

	const isOverLimit = useMemo(() => {
		if (!limit) return false;
		return allRoomCalc?.ADT + allRoomCalc?.CHD >= limit;
	}, [allRoomCalc]);

	const isEqualRoomGroups = useIsEqualRoomGroups(roomWatcher);

	useEffect(() => {
		setCurrentValue(form.getFieldValue(name ?? FIELD_NAME));
	}, [visible]);
	useEffect(() => {
		fieldsRef.current?.scrollIntoView({ behavior: "smooth", inline: "end" });
	}, [roomsValue]);
	return (
		<Drawer
			visible={visible}
			rootClassName={classes.PaxInfoDrawer}
			onClose={() => {
				form.setFieldValue(name ?? FIELD_NAME, currentValue);
				onCancel && onCancel();
			}}
		>
			<Form.List name={name ?? FIELD_NAME}>
				{(fields, { add, remove }) => (
					<>
						<div className={classes.PaxInfoDrawer__Fields}>
							{fields?.map(({ name, key, ...restField }, i) => {
								return (
									<Form.Item {...restField} key={key} className={classes.PaxInfoDrawer__FieldWrapper}>
										<h3 className={classes.PaxInfoDrawer__RoomGroupTitle}>
											{!isB2c ? t("sections.umrah.roomGroup") : t("common.sentence.room")} {i + 1}
										</h3>
										<div className={classes.PaxInfoDrawer__Field}>
											{i > 0 && (
												<Col span={24}>
													<div className={classes.PaxInfoDrawer__RemoveBtn}>
														<p className={classes.PaxInfoDrawer__RemoveBtn__Label}>{t("common.buttons.addRoom")}</p>
														<Space style={{ cursor: "pointer" }} onClick={() => remove(name)}>
															<p>{t("common.buttons.remove")}</p>
															<GreyCloseBtnIcon />
														</Space>
													</div>
												</Col>
											)}
											{/* <Col span={11}>
												<Space>
													<BedIcon />
													<p>{t("forms.fields.labels.roomCount")}</p>
												</Space>
											</Col> */}
											{/* <Col style={{ display: "flex" }} span={13}>
												{t("forms.fields.labels.guestInEachRoom")}
											</Col> */}
											{!isB2c && (
												<div className={classes.PaxInfoDrawer__Field__CounterWrapper}>
													<p>{t('umrah.success.card.roomCount')}</p>
													<Form.Item name={[name, "room_quantity"]} className={classes.PaxInfoDrawer__Input + " input-holder"}>
														<InputNumber
															controls={{
																upIcon: <PlusCircleFilled className={classes.PaxInfoDrawer__Input__Icon + " main-color"} />,
																downIcon: <MinusCircleFilled className={classes.PaxInfoDrawer__Input__Icon + " main-color"} />
															}}
															min={1}
														/>
													</Form.Item>
												</div>
											)}
											{/* <Col span={1} /> */}
											<div className={classes.PaxInfoDrawer__Field__CounterWrapper}>
												<p>{t("sections.umrah.adultsInEachRoom")}</p>
												<Form.Item name={[name, "ADT"]} className={[classes.PaxInfoDrawer__Input, classes.PaxInfoDrawer__InputWithLabel].join(" ") + " input-holder"}>
													<InputNumber
														controls={{
															upIcon: <PlusCircleFilled className={classes.PaxInfoDrawer__Input__Icon + " main-color"} />,
															downIcon: <MinusCircleFilled className={classes.PaxInfoDrawer__Input__Icon + " main-color"} />
														}}
														min={1}
														max={limit ? Math.min(limit - (allRoomCalc?.ADT + allRoomCalc?.CHD) + form.getFieldValue([FIELD_NAME, name, "ADT"]), 6) : 6}
														// disabled={isOverLimit}
													/>
												</Form.Item>
											</div>
											{/* <Col span={1} /> */}
											<div className={classes.PaxInfoDrawer__Field__CounterWrapper}>
												{/* <Space> */}
												<p>{t("sections.umrah.childsInEachRoom")}</p>
												<Form.Item name={[name, "CHD"]} className={[classes.PaxInfoDrawer__Input, classes.PaxInfoDrawer__InputWithLabel].join(" ") + " input-holder"}>
													<InputNumber
														controls={{
															upIcon: <PlusCircleFilled className={classes.PaxInfoDrawer__Input__Icon + " main-color"} />,
															downIcon: <MinusCircleFilled className={classes.PaxInfoDrawer__Input__Icon + " main-color"} />
														}}
														min={0}
														max={limit ? Math.min(limit - (allRoomCalc?.ADT + allRoomCalc?.CHD) + form.getFieldValue([FIELD_NAME, name, "CHD"]), 4) : 4}
													/>
												</Form.Item>
												{/* </Space> */}
											</div>
											<div className={classes.PaxInfoDrawer__TotalInfo}>
												<Space align="center" className={classes.PaxInfoDrawer__TotalInfo}>
													<p className={classes.PaxInfoDrawer__TotalInfo__Label}>{t('common.sentence.total')}:</p>
													<p className={classes.PaxInfoDrawer__TotalInfo__Text}>{`${form.getFieldValue([FIELD_NAME, name, "room_quantity"]) || 0} ${t("forms.fields.labels.rooms")}`}</p>
													<p className={classes.PaxInfoDrawer__TotalInfo__Text}>{`${(form.getFieldValue([FIELD_NAME, name, "ADT"]) || 0) * form.getFieldValue([FIELD_NAME, name, "room_quantity"])} ${t(
														"forms.fields.labels.adults"
													)}`}</p>
													<p className={classes.PaxInfoDrawer__TotalInfo__Text}>{`${(form.getFieldValue([FIELD_NAME, name, "CHD"]) || 0) * form.getFieldValue([FIELD_NAME, name, "room_quantity"])} ${t(
														"forms.fields.labels.childs"
													)}`}</p>
												</Space>
											</div>
											{form.getFieldValue([FIELD_NAME, name, "room_quantity"]) > 1 && form.getFieldValue([FIELD_NAME, name, "CHD"]) > 0 && (
												<div className={classes.PaxInfoDrawer__ChildWarning}>
													<h5 className={classes.PaxInfoDrawer__ChildWarning__Title}>{t("sections.umrah.childAges")}</h5>
													<p className={classes.PaxInfoDrawer__ChildWarning__Warning}>{t("sections.umrah.childAgesWarning")}</p>
												</div>
											)}
											<Row gutter={[12, 12]} className={classes.PaxInfoDrawer__Field__Childs}>
												{Array.from({ length: form.getFieldValue([FIELD_NAME, name, "CHD"]) }).map((x, index) => (
													<>
														<Col span={12}>
															<Form.Item name={[name, "age", index]} className="input-holder">
																<Select defaultValue={1} options={childAgesOptions}></Select>
															</Form.Item>
															<p className={classes.PaxInfoDrawer__Input__Suffix}>{t("forms.fields.labels.ages")}</p>
														</Col>
													</>
												))}
											</Row>
										</div>
									</Form.Item>
								);
							})}
							<div ref={fieldsRef}></div>
						</div>
						<div className={classes.PaxInfoDrawer__Actions}>
							<div
								className={classes.PaxInfoDrawer__Actions__AddBtn + " main-color"}
								onClick={() => {
									if (isOverLimit) return;
									add({ room_quantity: 1, ADT: 1, CHD: 0 });
								}}
							>
								<PlusCircleOutlined />
								<p>{isB2c ? t("common.buttons.addRoom") : t("common.buttons.addRoomsGroup")}</p>
							</div>
							<Button
								onClick={() => {
									if (isEqualRoomGroups) {
										message.error(`${t("common.buttons.Count of guests can't be the same in room")} ${isEqualRoomGroups.join(`${t("partner.and")}`)}`);
										return;
									}
									onFormlistModalSave();
								}}
								className={classes.PaxInfoDrawer__Actions__SaveBtn}
								type="primary"
							>
								{t("common.buttons.save")}
							</Button>
						</div>
					</>
				)}
			</Form.List>
		</Drawer>
	);
};

export default PaxInfoDrawer;
