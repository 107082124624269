import { Button } from "antd";
import React, { useEffect } from "react";
import { VisitKsaHeading } from "../style";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { VisitKsaExploreStyle } from "./VisitKsaExploreStyle";
import VisitKsaExploreCarousel from "./VisitKsaExploreCarsousel/VisitKsaExploreCarousel";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { useGetKsaActivitysList, useGetKsaPackagesList } from "@queries/visitKsa/useVisitKsa";
import queryClient from "@config/react-query/queryClient";
import { queryKeys } from "@config/react-query/queryKeys";
import { useSelector } from "react-redux";
import { useGetSlugActivity, useGetSlugPackages } from "@queries/packages/usePackage";
import { useRouter } from "next/router";

interface VisitKsaExploreProps {
	isThingsToDo?: boolean;
	companyId?: String;
}

const VisitKsaExplore: React.FC<VisitKsaExploreProps> = ({ isThingsToDo = false, companyId }) => {
	const { t, i18n } = useTranslation("website");
	const router = useRouter();

	// const { data, isFetching } = useGetSlugPackages({ lang: i18n?.language, isEnable: !isThingsToDo });
	const { data, isFetching } = useGetSlugPackages({
		body: {
			pageSize: 10,
			pageIndex: 0,
			sort: 1,
			companyId: companyId
		},
		slug: companyId && !isThingsToDo
	});
	const { data: activityData, isFetching: activityFetch } = useGetSlugActivity({
		body: {
			pageSize: 10,
			pageIndex: 0,
			sort: 1,
			companyId: companyId
		},
		slug: companyId && isThingsToDo
	});

	const { currency } = useSelector((store: any) => store?.user);
	const tPrefix = "visitKsa.explore";
	useEffect(() => {
		if (isThingsToDo) {
			queryClient.invalidateQueries([queryKeys.package.useGetSlugActivity]);
		} else {
			queryClient.invalidateQueries([queryKeys.package.useGetSlugPackages]);
		}
	}, [currency?.id]);
	return (
		(data?.paginationData?.items?.length !== 0 || activityData?.paginationData?.items?.length !== 0) && (
			<VisitKsaExploreStyle>
				<div className="container">
					<div className="visit-ksa--umrah__content">
						<VisitKsaHeading className="visit-ksa-explore-title">
							{isThingsToDo ? t(`${tPrefix}.Activity`) : t(`${tPrefix}.title`)}
							<Button type="link" className="visit-ksa-explore-see-more-btn">
								<Link href={isThingsToDo ? "/things-to-do?numberOfSeats=1" : "/p?numberOfSeats=1"}>{i18n.language === "en" ? <RightOutlined /> : <LeftOutlined />}</Link>
							</Button>
						</VisitKsaHeading>
						<div className="visit-ksa-explore-see-more-wrapper">
							{isThingsToDo ? <p>{t(`${tPrefix}.Activitysub`)}</p> : <p>{t(`${tPrefix}.subTitle`)}</p>}
							<Button type="link" className="visit-ksa-explore-see-more-btn">
								<a
									onClick={() => {
										if (isThingsToDo) {
											router.query.type = "activity";
											router.push(router);
										} else {
											router.query.type = "package";
											router.push(router);
										}
									}}
								>
									<p>{t("common.buttons.seeMore")}</p>
									{i18n.language === "en" ? <RightOutlined /> : <LeftOutlined />}
								</a>
							</Button>
						</div>
						<div>
							<VisitKsaExploreCarousel data={data?.paginationData?.items || activityData?.paginationData?.items} isLoading={isFetching ? isFetching : activityFetch} isThingsToDo={isThingsToDo} />
						</div>
					</div>
				</div>
			</VisitKsaExploreStyle>
		)
	);
};

export default VisitKsaExplore;
