import { useTranslation } from "next-i18next";
import { useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import SkeletonCard from "../SkeletonCard/SkeletonCard";
import dayjs from "dayjs";
import EmptyState from "../EmptyState/EmptyState";
import { sort_options } from "@helpers/fields";
import { Select } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { maxPriceRange } from "@common/constants";
import OwnPagination from "@components/Own/OwnPagination";
import PackageCard from "@components/cards/PackageCard/PackageCard";
import Api from "../../config/network/ApiConfig";
import SearchSide from "../SearchSide/SearchSide";
import { useSession } from "next-auth/react";
import { useGetSlugPackages } from "@queries/packages/usePackage";
import queryClient from "@config/react-query/queryClient";
import { queryKeys } from "@config/react-query/queryKeys";

function SlugPackage({ companyData }: any) {
	const router = useRouter();
	const { Option } = Select;
	const { t } = useTranslation("website");
	const [sortVal, setsortVal] = useState<any>(1);
	const [priceRange, setPriceRange] = useState<any>([0, maxPriceRange]);
	const [citiesList, SetCitiesList] = useState<any>([]);
	const [citiesSelectedList, SetCitiesSelectedList] = useState<any>([]);
	const [PastRouterQuery, setPastRouterQuery] = useState<any>("");
	const [currentPage, setCurrentPage] = useState<any>(0);
	const { currency } = useSelector((store: any) => store?.user);
	const { data: user } = useSession();
	if (user && [0, 1]?.includes(+user?.userType)) {
		router.push("/admin/security/admins");
		return <></>;
	}

	const handelGetParamsFromRouter = () => {
		const { destinationId, destinationType, minPrice, maxPrice, startDate, endDate, numberOfSeats, packageName, isAllPackageType, packageTypeIDs, CitiesIDs }: any = router.query;
		var isBodyEquel = JSON.stringify(router.query) == JSON.stringify(PastRouterQuery || "");
		let body = {
			pageSize: 10,
			pageIndex: isBodyEquel ? currentPage : 0,
			packageName: packageName || undefined,
			startDate: startDate ? dayjs(startDate).locale("en").format("YYYY-MM-DDTHH:mm:ss") : undefined,
			endDate: endDate ? dayjs(endDate).locale("en").format("YYYY-MM-DDTHH:mm:ss") : undefined,
			destinationId: destinationId || undefined,
			destinationType: +destinationType || undefined,
			numberOfSeats: +numberOfSeats || undefined,
			minPrice: +minPrice || undefined,
			maxPrice: +maxPrice || undefined,
			isAllPackageType: packageTypeIDs ? false : isAllPackageType === undefined ? true : isAllPackageType === "true",
			packageTypeIDs: packageTypeIDs ? packageTypeIDs.split(",") : undefined,
			CitiesIDs: CitiesIDs ? CitiesIDs.split(",") : undefined,
			sort: sortVal || undefined
			// sort: isBodyEquel ? sortVal : 1
		};
		setPastRouterQuery(router.query);
		if (!isBodyEquel) {
			setCurrentPage(0);
		}
		return body;
	};
	let newBody = useMemo(() => {
		return handelGetParamsFromRouter();
	}, [router.asPath, currentPage, sortVal]);

	const { data: packageData, isLoading } = useGetSlugPackages({ body: { ...newBody, companyId: companyData?.id }, slug: companyData?.profileName });

	useEffect(() => {
		const { CitiesIDs, maxPrice, minPrice }: any = router.query;
		var selectCities = CitiesIDs?.split(",");
		setPriceRange([minPrice || 0, maxPrice || packageData?.maxPrice]);

		if (selectCities?.length) {
			SetCitiesSelectedList(selectCities || []);
			SetCitiesList(packageData?.cityList || []);
		} else {
			SetCitiesSelectedList(selectCities || []);
			SetCitiesList(packageData?.cityList || []);
		}
		setPastRouterQuery(router.query);
		const root = document.querySelector("#__next");
		root?.scrollIntoView({
			behavior: "smooth"
		});
	}, [packageData]);

	useEffect(() => {
		setCurrentPage(0);
		queryClient.invalidateQueries([queryKeys.package.useGetSlugPackages]);
	}, [currency?.id]);

	const handelPackgeFav = (id: string) => {
		Api.put("PackagesAPI", `/Package/${id}/SetAsUserFavorite`, id).then(
			(res) => {
				queryClient.invalidateQueries([queryKeys.package.useGetSlugPackages]);
			},
			(err) => {
				console.log(err);
			}
		);
	};

	const handelSortPackge = (value: any) => {
		setsortVal(value);
		setCurrentPage(0);
	};

	return (
		<div className="SearchContainer">
			<div className="packages-wrapper" style={{ paddingTop: 0 }}>
				<div className="container">
					<div className="packages-content-wrapper">
						<SearchSide citiesList={citiesList} packageDataFilter={packageData} citiesSelectedList={citiesSelectedList} />
						<div className="result-container">
							<div className="result-container-sort">
								{!isLoading && !packageData?.paginationData?.items?.length ? (
									<p>
										<b>0</b> {t("profile.package.Packagesfound")}
									</p>
								) : (
									<p>
										<b>{packageData?.paginationData?.totalCount}</b> {t("profile.package.Packagematchesyoursearch")}
									</p>
								)}
								<div className="selection-sort-wrapper">
									<div className="SelectSection">
										<span className="SelectSection__name">{t("profile.package.sortby")}</span>
										<Select value={sortVal} onChange={handelSortPackge}>
											{sort_options(t)?.map((s: any) => (
												<Option value={s?.value}>{s?.name}</Option>
											))}
										</Select>
									</div>
									<div
										className="selection-row-holder"
										onClick={() => {
											document.querySelector(".search-aside-content")?.classList.add("active");
										}}
									>
										{" "}
										<FilterOutlined />
										{t("profile.package.Filter")}
									</div>
								</div>
							</div>
							<div className="result-list-wrapper">
								{isLoading && [1, 2, 3, 4].map((index) => <SkeletonCard style={{ marginBottom: 20 }} key={index} />)}
								{!isLoading &&
									packageData?.paginationData?.items?.map((s: any) => (
										<PackageCard
											//
											showFav
											numberOfSeats={router?.query?.numberOfSeats || 1}
											handelPackgeFavProps={(id: any) => handelPackgeFav(id)}
											data={s}
											key={s?.internalID}
										/>
									))}
								{!isLoading && !packageData?.paginationData?.items?.length && <EmptyState title={t("profile.package.Oops")} description={t("profile.package.Pleasechangedates")} />}
								<OwnPagination
									total={packageData?.paginationData?.totalCount}
									current={currentPage + 1}
									onChange={(index: number) => {
										setCurrentPage(index - 1);
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SlugPackage;
